<script setup lang="ts">
import { computed } from 'vue'

type AllowedColors = 'success' | 'error' | 'alert' | 'green'

const colorMap: Record<AllowedColors, string> = {
  success: 'bg-success',
  error: 'bg-error',
  alert: 'bg-alert',
  green: 'bg-green',
}

const { color, icon } = defineProps<{
  color?: AllowedColors
  icon?: string
}>()

const colorClass = computed(() => (color ? colorMap[color] : undefined))
</script>

<template>
  <div
    class="relative flex flex-col items-center justify-center rounded-xl bg-white p-4"
    v-bind="$attrs"
  >
    <!-- Ícone com Borda -->
    <div
      v-if="icon"
      class="absolute -top-4 z-10 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-md"
      aria-hidden="true"
    >
      <div
        v-if="color"
        class="flex h-10 w-10 items-center justify-center rounded-full"
        :class="colorClass"
      >
        <img :src="icon" class="h-6 w-6" alt="Icon" />
      </div>
    </div>

    <!-- Barra Superior Colorida -->
    <div
      v-if="color"
      class="absolute left-0 top-0 h-[8px] w-full rounded-t-xl"
      :class="colorClass"
    ></div>

    <!-- Slot para Conteúdo -->
    <slot />
  </div>
</template>
