<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import Dialog from '@/components/Dialog.vue'
import searchIcon from '@/assets/icons/grey/search.svg'

import { initialsPicture } from '@/utils/getInitialsPicture'
import Card from '@/components/Card.vue'
import warningIcon from '@/assets/icons/white/icon-warning.png'
import { Member } from '@/modules/member/types/members'

const props = defineProps<{
  isOpenOwner?: boolean
  members: Member[]
}>()

const searchMember = ref('')

const selectedMember = ref<Member | null>(null)
const emits = defineEmits(['close', 'search'])

const selectMember = (member: Member) => {
  selectedMember.value = selectedMember.value === member ? null : member
}

const saveSelection = () => {
  if (selectedMember.value !== null) {
    const userId = selectedMember.value.userId
    emits('close', userId)
  }
}

const emptyMembers = computed(() => {
  return !props.members.length && !searchMember.value
})

const pictureProfile = computed(() => {
  return props.members.map((member) => {
    const profilePicture = initialsPicture(member.name)
    return {
      ...member,
      profilePicture,
    }
  })
})

const getProfilePicture = (memberId: string) => {
  const member = pictureProfile.value.find((m) => m.userId === memberId)
  return member ? member.profilePicture : ''
}

function handleInput(event?: Event) {
  emits('search', (event?.target as HTMLInputElement)?.value)
}

onMounted(() => {
  handleInput()
})

watch(
  () => props.isOpenOwner,
  () => {
    searchMember.value = ''
  }
)
</script>

<template>
  <Dialog
    :open="props.isOpenOwner"
    :title="$t('corporate.detail.transferOwnership')"
    class="!w-[32rem]"
    @close="$emit('close')"
  >
    <div class="my-5 flex w-full flex-col items-center justify-center">
      <!-- Campo de busca -->
      <label class="relative my-2 block !w-full">
        <Input
          v-model="searchMember"
          :placeholder="$t('corporate.detail.search')"
          :disabled="emptyMembers"
          @input="handleInput"
        />
        <span class="absolute bottom-1 right-6 mt-2">
          <img :src="searchIcon" />
        </span>
      </label>

      <!-- Lista de membros -->
      <div class="h-96 w-full overflow-y-auto">
        <div v-if="emptyMembers" class="flex h-full w-full items-center">
          <Card color="alert" :icon="warningIcon" class="mt-4">
            <h3 class="py-2 text-center font-bold">
              {{ $t('general.advise') }}
            </h3>
            <p class="px-8 pb-4 text-center">
              {{ $t('corporate.detail.messageTransferOwnership') }}
            </p>
          </Card>
        </div>
        <div v-if="searchMember !== ''" class="h-full w-full bg-white">
          <div
            v-for="member in members"
            :key="member.userId"
            class="flex justify-center"
          >
            <span
              class="flex h-16 w-[27rem] items-start bg-white shadow-inner shadow-grey-6"
              @click="selectMember(member)"
            >
              <img
                :src="getProfilePicture(member.userId)"
                :class="{
                  'border-4 border-green': selectedMember === member,
                  'border-none': selectedMember !== member,
                }"
                class="mx-4 my-2.5 h-11 w-11 rounded-full bg-white"
              />
              <span class="flex flex-col">
                <h4
                  :class="{
                    'text-green': selectedMember === member,
                    'text-grey-0': selectedMember !== member,
                  }"
                  class="line-height-[1rem] pt-3.5 text-sm font-bold"
                >
                  {{ member.firstName + ' ' + member.lastName }}
                </h4>
                <h5 class="text-xs italic text-grey-0">
                  {{ member.permissionPresetDTO?.name }}
                </h5>
              </span>
            </span>
          </div>
        </div>
      </div>

      <!-- Botão transferir -->
      <div class="mt-3">
        <Button
          :disabled="selectedMember === null"
          class="self-center"
          @click="saveSelection"
        >
          {{ $t('corporate.detail.transfer') }}
        </Button>
      </div>
    </div>
  </Dialog>
</template>
