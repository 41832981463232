<script setup lang="ts">
import PillTag from '@/modules/contacts/components/PillTag.vue'
import { contactTypeClassMap } from '@/modules/contacts/utils/contactTypeClassMap'

defineProps<{ modelValue?: string[] }>()
const emit = defineEmits(['update:modelValue'])
</script>
<template>
  <div class="flex w-full flex-wrap items-center gap-2">
    <PillTag
      v-for="[k, v] in Object.entries(contactTypeClassMap)"
      :key="k"
      :disabled="!modelValue?.includes(k)"
      class="h-7 w-32 cursor-pointer"
      :class="modelValue?.includes(k) ? v : ''"
      @click="() => emit('update:modelValue', k)"
      >{{ $t(`contacts.corporative.${k}`) }}</PillTag
    >
  </div>
</template>
