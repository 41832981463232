<script setup lang="ts">
import menuDotsIcon from '@/assets/icons/menu_dots.svg'
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { computed, ref } from 'vue'

defineProps<{ disabled?: boolean }>()

const triggerRect = ref<DOMRect>()

// Calcula se o menu está na direita/esquerda ou topo/fundo
const bodyWidth = document.body.clientWidth
const bodyHeight = document.body.clientHeight

const isOnRightSide = computed(() => {
  return (triggerRect.value?.x || 0) / bodyWidth > 0.5
})

const isOnBottom = computed(() => {
  return (triggerRect.value?.y || 0) / bodyHeight > 0.5
})

// Estilos dinâmicos baseados no posicionamento do botão
const computedStyles = computed(() => {
  if (!triggerRect.value) return {}
  const { top, bottom, left, right } = triggerRect.value
  return {
    top: isOnBottom.value ? '' : `${bottom}px`,
    bottom: isOnBottom.value ? `${bodyHeight - top}px` : '',
    left: isOnRightSide.value ? '' : `${left}px`,
    right: isOnRightSide.value ? `${bodyWidth - right}px` : '',
  }
})

// Função para obter o posicionamento do botão
function toggleMenu(event: Event) {
  triggerRect.value = (
    event.target as HTMLButtonElement
  ).getBoundingClientRect()
}
</script>

<template>
  <Menu v-slot="{ close }" as="div" class="relative">
    <!-- Botão de Abertura -->
    <MenuButton
      id="menu-button"
      class="menu-button"
      as="button"
      :disabled="disabled || false"
      :aria-expanded="!disabled"
      aria-label="Menu Options"
      @click="toggleMenu"
    >
      <img
        :src="menuDotsIcon"
        alt="Menu Icon"
        class="rotate-90"
        :class="{ 'opacity-30': disabled, 'cursor-not-allowed': disabled }"
      />
    </MenuButton>

    <!-- Itens do Menu -->
    <Teleport to="body">
      <MenuItems
        as="div"
        class="absolute z-50 mt-2 w-max origin-top-left divide-y rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        :style="computedStyles"
      >
        <Card
          class="flex h-fit w-full !items-start !justify-start !text-left"
          color="green"
        >
          <div class="flex w-full flex-col">
            <!-- Slots para Conteúdo -->
            <slot name="menu-slot"></slot>
            <slot :close="close" />
          </div>
        </Card>
      </MenuItems>
    </Teleport>
  </Menu>
</template>
