<script setup lang="ts">
import { computed } from 'vue'

// Tipo para as cores permitidas
type AllowedColors = 'success' | 'error' | 'alert' | 'green'

// Definindo as propriedades do componente
const { color, icon, title } = defineProps<{
  color?: AllowedColors
  icon?: string
  title: string
}>()

// Computed para determinar a classe de cor com base na propriedade `color`
const colorClass = computed(() => {
  if (!color) return
  const colorMap = {
    success: 'bg-success',
    error: 'bg-error',
    alert: 'bg-alert',
    green: 'bg-green',
  }
  return colorMap[color as AllowedColors]
})
</script>

<template>
  <div
    class="relative flex flex-col justify-center rounded-xl bg-white p-4"
    v-bind="$attrs"
  >
    <!-- Barra de cor ao lado, caso a cor seja definida -->
    <div
      v-if="color"
      :class="[colorClass, 'absolute left-0 top-0 h-full w-[8px] rounded-l-xl']"
    ></div>

    <!-- Ícone e título -->
    <div
      v-if="icon"
      class="flex items-center pl-2"
      :class="'w-full flex-row space-x-2'"
    >
      <div
        v-if="color"
        class="flex items-center justify-center rounded-full"
        :class="'h-8 w-8'"
      >
        <img :src="icon" class="h-6 w-6" />
      </div>

      <div :class="'flex-1 pl-4 text-left'">
        <h3 class="py-2 text-left text-lg font-bold">{{ title }}</h3>
      </div>
    </div>

    <!-- Conteúdo do slot -->
    <div class="w-full pl-16 text-left">
      <slot />
    </div>
  </div>
</template>
