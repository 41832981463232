<script setup lang="ts">
import { RouteLocationRaw, useRouter } from 'vue-router'
import backArrow from '@/assets/icons/back.svg'

defineProps<{ to?: RouteLocationRaw }>()

const router = useRouter()
</script>
<template>
  <button v-bind="$attrs" @click="() => (to ? router.push(to) : router.back())">
    <img :src="backArrow" alt="" />
  </button>
</template>
