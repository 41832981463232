<script setup lang="ts" generic="T">
import searchIcon from '@/assets/icons/grey/search.svg'
import Input from '@/components/Input.vue'
import { computed, onMounted, onUnmounted, ref, toRefs, watch } from 'vue'
import closeIcon from '@/assets/icons/white/close.svg'
import {
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Combobox as HuiCombobox,
} from '@headlessui/vue'

defineOptions({
  inheritAttrs: false,
})
const props = withDefaults(
  defineProps<{
    id?: string
    optionsId?: string
    label: string
    required?: boolean
    items: T[] | null
    modelValue?: string | number | string[]
    labelField?: keyof T | string
    valueField?: keyof T | string
    loading?: boolean
    error?: boolean
    placeholder?: string
    multiple?: boolean
    initialValue?: string | number | Array<unknown>
    disabled?: boolean
    value?: unknown | unknown[]
  }>(),
  {
    id: '',
    optionsId: '',
    required: false,
    labelField: 'label',
    valueField: 'value',
    modelValue: undefined,
    loading: false,
    error: false,
    placeholder: '',
    messageAfterOptions: '',
    multiple: false,
    initialValue: undefined,
    disabled: false,
    value: undefined,
  }
)

const { label, items } = toRefs(props)

const emit = defineEmits([
  'change',
  'click',
  'update:modelValue',
  'select',
  'submit',
  'search',
])

const selectedItem = ref(
  props.multiple
    ? [...(Array.isArray(props.initialValue) ? props.initialValue : [])]
    : props.initialValue
)

const handleOptionClick = (item: (typeof items.value)[number]) => {
  value.value = ''
  emit('click', item)
}

const localQuery = ref()

const value = computed({
  get() {
    return props.modelValue || localQuery.value
  },
  set(value) {
    emit('update:modelValue', value)
    localQuery.value = value
  },
})

const handleSubmit = () => {
  emit('submit', selectedItem.value)
}

function handleInput(event?: Event) {
  emit('search', (event?.target as HTMLInputElement)?.value)
  value.value = (event?.target as HTMLInputElement)?.value
}

function handleDeleteItem(idx: number) {
  if (!props.multiple) return

  Array.isArray(selectedItem.value)
    ? selectedItem.value.splice(idx, 1)
    : (selectedItem.value = [])
  emit('select', selectedItem.value)
}

const searchedItems = computed(() =>
  items.value.filter((i: object) =>
    (i[props.labelField as keyof typeof i] as string)
      .toLowerCase()
      .includes(value.value.toLowerCase())
  )
)
function updateSelection() {
  if (props.multiple) {
    selectedItem.value = props.items?.filter((item) => {
      return (
        Array.isArray(props.value) &&
        (props.value as unknown[])
          ?.map((v) => String(v))
          ?.includes(String(item[props.valueField as keyof typeof item]))
      )
    })

    emit('select', selectedItem.value)
  } else
    selectedItem.value = String(
      props.items?.find(
        (item) => item[props.valueField as keyof typeof item] === props.value
      )
    )
}

watch(
  () => props.items,
  (newV) => {
    if (newV?.length) updateSelection()
    else selectedItem.value = props.multiple ? [] : undefined
  }
)
watch(
  () => props.value,
  (newV, oldV) => {
    if (!newV || typeof oldV !== typeof newV)
      selectedItem.value = props.multiple ? [] : undefined
  }
)

onMounted(updateSelection)
onUnmounted(() => {
  value.value = ''
})
</script>

<template>
  <HuiCombobox
    v-slot="{ disabled: isDisabled }"
    v-model="selectedItem"
    :multiple="multiple"
    :disabled="props.disabled"
    @update:model-value="
      (v) => {
        $emit('select', v)
        value = undefined
      }
    "
  >
    <div
      class="relative w-full text-center"
      :class="{ 'opacity-50': isDisabled }"
    >
      <div v-if="multiple">
        <div class="flex w-full overflow-hidden">
          <ComboboxInput
            :id="props.id"
            v-model="value"
            :display-value="() => (value ? String(value) : '')"
            :label="label"
            :placeholder="selectedItem == null ? placeholder : ''"
            :as="Input"
            class="mr-2 flex w-full grow overflow-hidden outline-none"
            :icon="searchIcon"
            icon-position="end"
            @click="handleSubmit"
            @input="handleInput"
          />
        </div>

        <div
          v-if="(selectedItem as Array<unknown>)?.length > 0"
          class="z-10 flex flex-row flex-wrap"
        >
          <Chip
            v-for="(chip, cidx) in selectedItem"
            :key="(chip as object)[props.valueField as keyof typeof chip]"
            class="my-1 text-nowrap rounded-xl bg-grey-3 px-2 py-1 text-sm font-bold text-white"
          >
            {{ (chip as object)[props.labelField as keyof typeof chip] }}
            <span
              class="ml-2 cursor-pointer"
              @click="() => handleDeleteItem(Number(cidx))"
            >
              <img class="w-4" :src="closeIcon"
            /></span>
          </Chip>
          <Chip
            class="my-1 cursor-pointer text-nowrap rounded-xl border-[1px] border-grey-3 bg-white px-2 py-1 text-sm font-bold text-grey-3"
            @click="
              () => {
                selectedItem = []
                emit('select', selectedItem)
              }
            "
          >
            {{ $t('general.cleanSelection') }}
          </Chip>
        </div>
      </div>
      <div v-else class="relative w-full">
        <ComboboxInput
          :id="props.id"
          v-model="value"
          :as="Input"
          :label="label"
          :placeholder="placeholder"
          class="w-full"
          @change="(e) => $emit('change', e)"
        >
        </ComboboxInput>
        <ComboboxButton
          class="absolute inset-y-0 right-0 mb-1 mr-1 mt-2 flex w-8 items-center bg-white pl-1"
          :class="label ? 'top-8' : ''"
          :disabled="true"
        >
          <img :src="searchIcon" class="bg-white" />
        </ComboboxButton>
      </div>
      <ComboboxOptions
        :id="props.optionsId"
        class="absolute top-[4.5rem] z-50 max-h-[10rem] w-full overflow-y-auto rounded-b-md border-grey-4 bg-white shadow-md"
        @close="value = ''"
      >
        <ComboboxOption
          v-for="item in searchedItems"
          :id="'combobox-option-' + item.id"
          v-slot="{ active, selected }"
          :key="item.id"
          :value="item"
          @click="handleOptionClick(item)"
        >
          <hr />
          <li
            :class="{
              'bg-green text-white': active,
              'bg-white text-black': !active,
              'font-bold': selected,
            }"
            class="flex h-7 items-center overflow-hidden text-ellipsis text-nowrap pl-3"
          >
            {{ item[labelField as keyof typeof item] }}
          </li>
        </ComboboxOption>

        <ComboboxOption class="sticky bottom-0 w-full cursor-pointer">
          <slot></slot>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </HuiCombobox>
</template>
