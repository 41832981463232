<script setup lang="ts"></script>

<template>
  <!-- Loader container com posicionamento absoluto para cobrir toda a tela -->
  <div class="loader absolute flex h-full w-full items-center justify-center">
    <!-- Animação de loader com borda e efeito de rotação -->
    <div
      class="h-[20px] w-[20px] animate-spin rounded-3xl border-[4px] border-t-[4px] border-solid border-grey-6 border-t-green"
    ></div>
  </div>
</template>
