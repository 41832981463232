import { AddressService } from '@/services/address'
import { City, Country, State } from '@/types/address'
import { onMounted, ref, watch } from 'vue'
import { z } from 'zod'
const countries = ref<Country[]>()
export const useAddress = () => {
  const cities = ref<City[]>()
  const states = ref<State[]>()
  const loading = ref<boolean>(false)
  const loadingCities = ref<boolean>(false)
  const loadingStates = ref<boolean>(false)
  const loadingCountries = ref<boolean>(false)

  const selectedCountryCodes = ref<string[]>()
  const selectedStateCodes = ref<string[]>()
  const selectedCityCodes = ref<string[]>()

  async function getCountries() {
    countries.value = []
    states.value = []
    cities.value = []
    loadingCountries.value = false
    const { data, error } = await AddressService.getCountries()
    loadingCountries.value = false
    if (error || !data) return
    countries.value = data.map((c) => ({
      ...c,
      zodRule: z.custom<'postalCodeRegex'>((value) => {
        if (!value) {
          return true
        }
        if (c.postalCodeRegex) {
          const regex = new RegExp(c.postalCodeRegex)
          return typeof value === 'string' ? regex.test(value) : false
        }

        return true
      }, 'validation.postalCode'),
    }))
  }
  async function getCountryStates(countryCode: string | string[]) {
    states.value = []
    cities.value = []
    loadingStates.value = true
    const { data, error } = await AddressService.getCountryStates(countryCode)
    loadingStates.value = false
    if (error || !data) return
    states.value = data
    return states.value
  }
  async function getStateCities(stateCode: string | string[]) {
    cities.value = []
    loadingCities.value = true
    const { data, error } = await AddressService.getStateCities(stateCode)
    loadingCities.value = false
    if (error || !data) return
    cities.value = data
    return cities.value
  }

  function selectCountries(countryCodes: string[]) {
    selectedCountryCodes.value = countryCodes
  }

  function selectStates(stateCodes: string[]) {
    selectedStateCodes.value = stateCodes
  }

  function selectCities(cityCodes: string[]) {
    selectedCityCodes.value = cityCodes
  }

  watch(selectedCountryCodes, () =>
    selectedCountryCodes.value
      ? getCountryStates(selectedCountryCodes.value)
      : (selectedStateCodes.value = [])
  )
  watch(selectedStateCodes, () =>
    selectedStateCodes.value
      ? getStateCities(selectedStateCodes.value)
      : (selectedCityCodes.value = [])
  )

  onMounted(getCountries)
  return {
    loading,
    loadingCities,
    loadingStates,
    loadingCountries,
    cities,
    states,
    countries,
    getCountries,
    getStateCities,
    getCountryStates,
    selectedCountryCodes,
    selectedStateCodes,
    selectedCityCodes,
    selectCountries,
    selectStates,
    selectCities,
  }
}
